import { useEffect } from 'react';

const LoadScript = (url) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.type = "text/javascript"
    script.async = true;

    document.body.appendChild(script);
    // setTimeout(() => {
    //   if(!!window.getSessionID){
    //     window.getSessionID(function (sessionId) {
    //     // Capture sessionId to later send in API calls
    //       window.koinSessionId = sessionId;
    //       return sessionId;
    //     })
    //   }
      
    // }, 1000);
    
    
    return () => {
      document.body.removeChild(script);      
    }
  }, [url]);

  return true;
};

/* const GetKoinSessionID = () => {
  
} */

export default LoadScript;
