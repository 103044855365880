import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
//import api from "../../services/api";
import LoadScript from "../../infra/LoadScript";

import './PrintingTickets.scss';

import iconNotApproved from '../../assets/icons/not-approved.svg';
import iconApproved from '../../assets/icons/approved.svg';
import iconPrintingTickets from '../../assets/icons/printing-tickets.svg';

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { removeTotalCart } from '../../store/cart';
import { Link } from "react-router-dom";
import api from "../../services/api";

declare global {
    interface Window {
        BrowserPrint?: any;
    }
}

export interface checkout {
    printer?: any;
};

const PrintingTickets: React.FC<checkout> = React.memo(({ printer
}: checkout) => {
    const [t] = useTranslation();

    const dispatch = useAppDispatch();
    const cartTotem: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.cart)));

    /* const [chooseDevice, setChooseDevice] = useState<any>('');
    const [available, setAvailabe] = useState<number>(0); */
    const [management, setManagement] = useState('imprimindo');

    const [paper, setPaper] = useState<any>('');
    const [stop, setStop] = useState<any>('');

    const [messagePrint, setMessagePrint] = useState<any>('');

    const [errorCode, setErrorCode] = useState<string>("");

    const [isPrint, setIsPrint] = useState<boolean>(false);

    LoadScript('/static/js/BrowserPrint-3.0.216.min.js');

    const zplInfo: any = localStorage.getItem('printZpl');
    const zpl = JSON.parse(zplInfo);

    const dateChanged: any = new Date().getTime();

    function Reset() {
        if (printer === undefined) {
            localStorage.removeItem("ticketDestinow");
            window.location.href = "/totem/resgatar-compra";
        } else {
            window.location.href = "/totem";
            dispatch(removeTotalCart(cartTotem.dados));
        }
    }

    function Success() {
        if (printer === undefined) {
            window.location.href = "/totem/resgatar-compra";
        } else {
            window.location.href = "/totem";
            dispatch(removeTotalCart(cartTotem.dados));
        }
    }

    console.log(printer)

    useEffect(() => {
        if (printer !== undefined) {
            coupomTicket(printer); // Compra
        } else {
            const reprint: any = localStorage.getItem("ticketDestinow");
            coupomTicket(JSON.parse(reprint)); // Reimpressão
        }
    }, [isPrint])

    const coupomTicket = useCallback(async (printer) => { // Impressora BEMATCH

        try {
            const { data } = await api.post(`http://localhost:5000/api/impressao/totem/mp4200`, printer);
            if (data?.statusCode !== 400) {
                setTimeout(() => {
                    setManagement('success');
                }, 5000);
            } else {
                setManagement("error");
                //setReprintTickets(true);
            }
        } catch (e) {
            setManagement("error");
            //setReprintTickets(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <div className="content-totem printing-tickets bg-primary">
                <div className="bg-header-custom">
                    <img src={process.env.REACT_APP_SERVER_LOGO} alt="icon Bondinho" width="450px" style={{ marginTop: '60px' }} />
                </div>
                {
                    management === "imprimindo"
                        ?
                        <>
                            <div className='content-title'>
                                <h2 className="text-primary text-center my-inside">
                                    {t("checkout.printingTickets.title")}
                                </h2>
                            </div>
                            <div style={{ overflow: "hidden", height: "356px" }}>
                                <img className="printer-icon" src={iconPrintingTickets} alt="icon not approved" />
                            </div>
                            <div style={{ marginTop: "6rem" }}>
                                <p style={{ color: "#FFF", fontSize: "1.5rem" }}>{messagePrint}</p>
                            </div>
                        </>
                        :
                        management === "error"
                            ?
                            <>
                                <div className='content-title'>
                                    <h2 className="text-primary text-center my-inside">
                                        {t("checkout.error.title")}
                                    </h2>
                                    <p className="mb-1" style={{ color: "#FFFFFF", fontSize: "1.25rem", fontWeight: 600 }}>{errorCode !== "" ? `Erro: ${errorCode}` : ""}</p>
                                </div>
                                <div style={{ overflow: "hidden", height: "356px" }}>
                                    <img src={iconNotApproved} alt="icon not approved" />
                                </div>
                                <div className="printing-buttons">
                                    <button className="btn btn-printing-custom text-white py-3" onClick={Reset}>
                                        {t("checkout.error.tryAgain")}
                                    </button>
                                </div>
                            </>
                            :
                            management === "success"
                                ?
                                <>
                                    <div className='content-title'>
                                        <h2 className="text-primary text-center my-inside">
                                            {
                                                printer === undefined
                                                    ?
                                                    <>
                                                        {t("printing.printSuccess")}
                                                    </>
                                                    :
                                                    <>
                                                        {t("printing.buySuccess")}
                                                    </>
                                            }
                                        </h2>
                                    </div>
                                    <div style={{ overflow: "hidden", height: "356px" }}>
                                        <img src={iconApproved} alt="icon not approved" />
                                    </div>
                                    <div className="printing-buttons">
                                        {
                                            printer !== undefined
                                                ?
                                                <button className="btn btn-printing-custom text-white py-3" onClick={Success}>
                                                    {t("printing.return")}
                                                </button>
                                                :
                                                <>
                                                    <button className="btn btn-printing-custom text-white py-3" onClick={Success}>
                                                        {t("printing.printMore")}
                                                    </button>
                                                    <br />
                                                    <Link to="/totem" className="btn btn-printing-custom text-white py-3">
                                                        {t("printing.return")}
                                                    </Link>
                                                </>
                                        }
                                    </div>
                                </>
                                :
                                <>
                                </>
                }
            </div>
        </>
    )
});


export default PrintingTickets;