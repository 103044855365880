import React, { Suspense, useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from "./store/hooks";
import { removeTotalCart } from './store/cart';

import PreLoader from './pages/PreLoader/PreLoader';
import PrintingTickets from './pages/PrintingTickets/PrintingTickets';

const Start = React.lazy(() => import('./pages/Start/Start'));
const Options = React.lazy(() => import('./pages/Options/Options'));
const Products = React.lazy(() => import('./pages/Products/Products'));
const SingleProduct = React.lazy(() => import('./pages/SingleProduct/SingleProduct'));
const PersonalData = React.lazy(() => import('./pages/PersonalData/PersonalData'));
const Cart = React.lazy(() => import('./pages/Cart/Cart'));
const Checkout = React.lazy(() => import('./pages/Checkout/Checkout'));
//const PayPix = React.lazy(() => import('./pages/PayPix/PayPix'));
const Approved = React.lazy(() => import('./pages/Approved/Approved'));
/* const PrintingTickets = React.lazy(() => import('./pages/PrintingTickets/PrintingTickets')); */
const NotApproved = React.lazy(() => import('./pages/NotApproved/NotApproved'));
const Summary = React.lazy(() => import('./pages/Summary/Summary'));

function Routes() {

    const [path, setPath] = useState<string>(window.location.pathname);
    const cartTOTEM = useAppSelector((state) => state.cart);
    const dispatch = useAppDispatch();

    /* Inatividade - Retornar para Home */
    useEffect(() => {
        inactivityTime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const inactivityTime = function () {
        let time: any;
        // reset timer
        setPath(window.location.pathname);

        if (path === "/totem") {
        } else {
            if (path === "/totem/checkout" 
            || path === "/totem/imprimindo-ingressos"
            || path === "/totem/options"
            || path === "/totem/compra-rapida"
            || path === "/totem/dados-pessoais"
            || path === "/totem/carrinho") {
                resetTimer();
            }

            window.onload = resetTimer;
            document.ontouchend = resetTimer;
            document.ontouchend = resetTimer;
            document.onclick = resetTimer;
        }

        function doSomething() {
            dispatch(removeTotalCart(cartTOTEM.dados));
            window.location.href = "/totem"
        }

        function resetTimer() {
            //console.log("ativo")
            clearTimeout(time);

            if (path === "/totem/checkout" || path === "/totem/imprimindo-ingressos") {
                time = setTimeout(doSomething, 780000) // 13 minutos
            } else {
                time = setTimeout(doSomething, 780000) // 2 minutos
            }
        }
    };
    /* Inatividade - Retornar para Home */

    return (
        <Suspense fallback={<PreLoader />}>
            <Switch>
                {/* <Route path="/" exact>
                    <Redirect to="/totem" />
                </Route> */}
                {/* <Route path="/" exact ><Start /></Route> */}
                <Route path="/totem" exact ><Start /></Route>
                <Route path="/totem/options" exact ><Options /></Route>
                <Route path="/totem/products" exact ><Products /></Route>
                <Route path="/totem/compra-rapida/:slug" exact ><SingleProduct /></Route>
                <Route path="/totem/dados-pessoais" exact ><PersonalData /></Route>
                <Route path="/totem/carrinho" exact ><Cart /></Route>
                <Route path="/totem/checkout" exact ><Checkout /></Route>
                {/* <Route path="/instrucoes" exact ><Instructions/></Route> */}
                <Route path="/totem/compra-aprovada" exact ><Approved /></Route>
                <Route path="/totem/imprimindo-ingressos" exact ><PrintingTickets /></Route>
                <Route path="/totem/compra-nao-aprovada" exact ><NotApproved /></Route>
                <Route path="/totem/resgatar-compra" exact ><Summary /></Route>
                {/* <Route path="/resumo-da-compra" exact ><SummaryBuy/></Route>  */}
            </Switch>
        </Suspense>
    );
}

export default Routes;